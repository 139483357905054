// Small service used to map subscription IDs to subscription names
// to prevent altering Stargate's environment variables
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionMapService {
  
  private subMap: Map<string, string> = new Map();
  
  constructor() {
    this.initializeSubscriptionMappings();
  }

  initializeSubscriptionMappings() {
    // sadly the environment variables are non-uniform across clouds
    const az_environment = environment.Azure.DEV_SUB_IDS.concat(environment.Azure.PROD_SUB_IDS);
    for (let sub of az_environment) {
      if (sub && sub.id && sub.name) {
      this.subMap.set(sub.id, sub.name);
      } else {
      console.warn('Invalid subscription:', sub);
      }
    }
    const aws_environment = environment.AWS.ACCOUNTS;
    for (let acct in aws_environment) {
      this.subMap.set(acct, aws_environment[acct].name);
    }
  }

  getSubscriptionName(subscriptionId: string): string {
    return this.subMap.get(subscriptionId);
  }
}
