import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AlertBroadcastService } from 'src/app/common-components/alert-modal/alert-broadcast.service';
// note: may add reload schedule event to refresh schedule view state
import { ScheduleDeprecatedDeletionEvent, ScheduleOpenDialogEvent } from 'src/app/events';
import { TabInfo, VMScheduleDetailsTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { SchedDetailsBase } from './data-models/sched-details-base';
import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { CloudType } from 'src/app/constants';

@Component({
  selector: 'app-sched-details-modal',
  templateUrl: './sched-details-modal.component.html',
  styleUrls: ['./sched-details-modal.component.less']
})
export class SchedDetailsModalComponent implements OnInit {

  @Output() orphanScheduleDeleted = new EventEmitter<ScheduleDeprecatedDeletionEvent>();
  @ViewChild('tabGroup') public tabGroup;
  @ViewChild('content') public contentModal;

  schedDetails: SchedDetailsBase;

  isLoading: boolean = true;

  scheduleID: string;
  vmId: string;
  cloudType: CloudType;
  vmDetails: any;

  schedInfo: TabInfo;


  // debating if we need VMSchedUpdateEvent
  // for DetailsModal - it tries to do a lookup on the Azure/AWS API to see 
  // if its in deallocated state - but for our DB we only have two states 
  // present or not present
  constructor(
    private stargateAPI: StargateApiService,
    private alertBroadcast: AlertBroadcastService) {
      this.schedInfo = new TabInfo("Schedule Details");
  }

  async onReloadScheduleEvent() {
    await this.schedDetails.loadDetails();
  }

  showScheduleDetails(evt: ScheduleOpenDialogEvent) {
    this.schedDetails = new SchedDetailsBase(this.stargateAPI, this.alertBroadcast, evt);
    this.loadContent();
  }

  updateModal() {
    this.scheduleID = this.schedDetails.getScheduleID();
    this.vmId = this.schedDetails.getVmID();
    this.cloudType = this.schedDetails.getCloudType();
    this.vmDetails = this.schedDetails.getVmDetails();
    this.schedInfo = this.schedDetails.getScheduleInfoTab();

    this.isLoading = false;
  }

  async loadContent() {
    this.isLoading = true;
    this.tabGroup.selectFirstTab();
    this.contentModal.show();

    await this.schedDetails.loadDetails();
    this.updateModal();
  }
  
  ngOnInit() {
  }
  
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async deleteSchedule() {
    // console.log(`Delete schedule event initiated, target document schedule of ${this.vmId}`);
    if (this.cloudType == CloudType.AZURE) {
      await this.schedDetails.deleteSchedule(this.vmId, this.cloudType, null);
    } else if (this.cloudType == CloudType.AWS) {
      await this.schedDetails.deleteSchedule(this.vmId, this.cloudType, this.vmDetails.accountId);
    }

    await this.delay(3000);
    this.orphanScheduleDeleted.emit(new ScheduleDeprecatedDeletionEvent(this.scheduleID, this.vmId));
  }

}
