export const environment = {
  EnvName: "sandbox",
  production: false,
  Azure: {
    AUTH_ENDPOINT: "https://login.microsoftonline.com",
    TENANT_ID: "a7499e28-6a7f-48d9-8b14-d3e690989660",
    APP_CLIENT_ID: "2834ac78-2716-44a0-bf57-309d3d89b0eb",
    REDIRECT_URL: "https://stargate-dev.weyer.com/",
    PROD_SUB_IDS: [],
    DEV_SUB_IDS: [ { id: "75eb9b4d-5f7d-4eb3-9f7d-8ac01e6c9778", name: "Shared Services Sandbox" } ],
    FUNCTIONS_ENDPOINT: "https://isw2sbxappstargate01.azurewebsites.net/api"
  },
  AWS: {
    AUTH_ENDPOINT: "https://launcher.myapps.microsoft.com/api/signin/525a2e88-e627-414c-8872-2d070e781ef3?tenantId=a7499e28-6a7f-48d9-8b14-d3e690989660",
    ACCOUNTS: {
      '973700593114': { name: "Shared Sandbox" }
    },
    FILTER_ON_TEAM_NAME: false,
    DEV_CREDS: null
  }
};
  