import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VMScheduleDetailsTabInfo } from 'src/app/common-components/tabs/TabInfo';
import { CloudType } from 'src/app/constants';
import { SubscriptionMapService } from 'src/app/subscription-map/subscription-map.service';
@Component({
  selector: 'app-sched-info-tab',
  templateUrl: './sched-info-tab.component.html',
  styleUrls: ['./sched-info-tab.component.less']
})
export class SchedInfoTabComponent implements OnChanges {
  @Input() tabInfo: VMScheduleDetailsTabInfo;

  properties: any[] = [];
  isLoading: boolean = false;

  constructor(private subMap: SubscriptionMapService) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabInfo) {
      this.properties = [];
      this.getScheduleProperties(this.tabInfo);
    }
  }

  getScheduleProperties(schedDetails: VMScheduleDetailsTabInfo) {
    // reuse member variables from deprec-sched-grid-items
    // scheduleID: string;
    // vmId: string;
    // cloudType: CloudType;
    // vmDetails: any;
    this.properties.push({label : "Schedule ID", value: schedDetails.scheduleID});
    this.properties.push({label: "Virtual Machine ID", value: schedDetails.vmId});
    this.properties.push({label: "Cloud Type", value: schedDetails.cloudType === 0 ? 'Azure' : 'Amazon Web Services' })
    if (schedDetails.cloudType === 0) {
      this.properties.push({label: "Subscription ID Origin", value: schedDetails.vmDetails.subscriptionId});
      this.properties.push({label: "Subscription Name", value: this.getSubscriptionName(schedDetails.vmDetails.subscriptionId)});
      this.properties.push({label: "Resource Group Origin", value: schedDetails.vmDetails.resourceGroup});
      this.properties.push({label: "Virtual Machine Name", value: schedDetails.vmDetails.vmName});
    } else if (schedDetails.cloudType === 1){
      this.properties.push({label: "AWS Account ID Origin", value: schedDetails.vmDetails.accountId});
      this.properties.push({label: "Account Name", value: this.getSubscriptionName(schedDetails.vmDetails.accountId)});
    }
  }

  getSubscriptionName(value: string): string {
    return this.subMap.getSubscriptionName(value) ? this.subMap.getSubscriptionName(value) : 'Unknown Subscription';
  }

}
