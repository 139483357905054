import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { getAzureToken, getAWSSession } from "src/utils/storageHelper";
import { CloudType } from "src/app/constants";

@Injectable({
  providedIn: 'root'
})
export class StargateApiService {

  constructor(private http: HttpClient) { }

  getSchedule(vmID: string, cloudType: CloudType, awsAccountID: string = null) {
    return this.http.get(`${environment.Azure.FUNCTIONS_ENDPOINT}/GetSchedule?instanceId=${vmID}&cloudType=${cloudType}`,
      this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  setSchedule(vmID: string, cloudType: CloudType, schedule: object, awsAccountID: string = null) {
    return this.http.post(`${environment.Azure.FUNCTIONS_ENDPOINT}/SetSchedule?instanceId=${vmID}&cloudType=${cloudType}`, schedule,
      this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  deleteSchedule(vmID: string, cloudType: CloudType, actionId: string, awsAccountID: string = null) {
    return this.http.delete(`${environment.Azure.FUNCTIONS_ENDPOINT}/DeleteSchedule?instanceId=${vmID}&cloudType=${cloudType}&actionId=${actionId}`,
      this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  // variant of deleteSchedule but to do forceful deletion of schedule documents in mongoDB 
  forceDeleteScheduleDoc(vmID: string, cloudType: CloudType, awsAccountID: string = null) {
    return this.http.delete(`${environment.Azure.FUNCTIONS_ENDPOINT}/DeleteSchedule?instanceId=${vmID}&forceDeleteDocuments=true`, this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  getDropdowns(dropdownName: string, cloudType: CloudType, awsAccountID: string = null) {
    return this.http.get(`${environment.Azure.FUNCTIONS_ENDPOINT}/GetDropdowns?dropdownName=${dropdownName}`,
      this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  getMaxSnapshotsPerDisk(cloudType: CloudType, awsAccountID: string = null) {
    return this.getConfig("maxSnapshotsPerDisk", cloudType, awsAccountID);
  }

  getAllowCreateVMUserList(cloudType: CloudType, awsAccountID: string = null) {
    return this.getConfig("allowCreateVMUserList", cloudType, awsAccountID);
  }

  getErrorNotificationEmailList(cloudType: CloudType, awsAccountID: string = null) {
    return this.getConfig("errorNotificationEmailList", cloudType, awsAccountID);
  }

  listSchedules(deprecatedSchedules: boolean) {
    return this.http.get(`${environment.Azure.FUNCTIONS_ENDPOINT}/ListSchedules?deprecatedSchedules=${deprecatedSchedules}`);
  }

  private getConfig(configType: string, cloudType: CloudType, awsAccountID: string = null) {    
    return this.http.get(`${environment.Azure.FUNCTIONS_ENDPOINT}/GetConfig?configType=${configType}`,
      this.setHeadersForCloudType(cloudType, awsAccountID));
  }

  private setHeadersForCloudType(cloudType: CloudType, awsAccountID: string) {
    var headers: HttpHeaders = null;

    if (cloudType == CloudType.AZURE)
      headers = new HttpHeaders({
        "azure-token": getAzureToken() || "",
        "cloud-type": cloudType.toString() || ""
      });
    else if (cloudType == CloudType.AWS && awsAccountID != null) {
      var session = getAWSSession(awsAccountID);
      if (session && session.credentials) {
        headers = new HttpHeaders({
          "access-key-id": session.credentials["accessKeyId"] || "",
          "secret-access-key": session.credentials["secretAccessKey"] || "",
          "session-token": session.credentials["sessionToken"] || "",
          "cloud-type": cloudType.toString() || ""
        });
      }
    }

    return { headers: headers || new HttpHeaders({"unauthorized": "true"}) }
  }
}
