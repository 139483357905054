import { interval } from 'rxjs';
import {  EventEmitter} from '@angular/core';

import { AzureApiService } from 'src/app/azure/azure-api/azure-api.service';
import { StargateApiService } from 'src/app/stargate-api/stargate-api.service';
import { getAzureUserEmail } from 'src/utils/storageHelper';
import { searchForValue, unsubscribePendingVM } from 'src/utils/misc';
import { VMPermissions } from 'src/app/dashboard/VMPermissions';
import { VMStatusUpdateEvent, AzureRequestMonitorStatusEvent } from 'src/app/events';
import { CloudType, VMState } from 'src/app/constants';
import { VMStatus } from 'src/app/dashboard/VMStatus';

// Continually calls the Azure API with a specific URL that is returned from a
// VM action API request to let the application know what the status is of the VM. It is
// designed to be called on a set interval until the status changes from "InProgress".  
export function monitorAzureStatusWithAsyncUrl(azureAPI: AzureApiService, vmID: string, asyncUrl: string, retrySeconds: number, pendingVMs: any): Promise<object> {
    return new Promise<object>((resolve, reject) => {
        pendingVMs[vmID] = interval(1000 * retrySeconds)
            .subscribe(() => {
                azureAPI.getOperationStatus(asyncUrl).toPromise()
                    .then(response => {
                        if (response["status"] != "InProgress") {
                            unsubscribePendingVM(vmID, pendingVMs);
                            resolve(response);
                        }
                    })
                    .catch(e => {
                        unsubscribePendingVM(vmID, pendingVMs);
                        reject(e);
                    })
            });
    });
}

// Continually calls the Azure API to get the VM details on a set interval
// to check if it's status changes from pending to a final state. This
// is used when a VM is starting, stopping, or resizing to help the application
// display the correct status.
export function monitorAzureStatus(azureAPI: AzureApiService, vmID: string, subscriptionID: string, resourceGroup: string, vmName: string, pendingVMs: any, retrySeconds: number = 10): Promise<object> {
    return new Promise<object>((resolve, reject) => {
        pendingVMs[vmID] = interval(1000 * retrySeconds)
            .subscribe(() => {
                azureAPI.getVMDetails(subscriptionID, resourceGroup, vmName).toPromise()
                    .then(response => {
                        var powerStateStatus = (searchForValue(response, ["properties", "instanceView", "statuses"]) || []).find(status => status.code.indexOf("PowerState") != -1);
                        if (!powerStateStatus || !powerStateStatus.displayStatus) {
                            unsubscribePendingVM(vmID, pendingVMs);
                            reject("Couldn't get status");
                        }
                        else if (powerStateStatus.displayStatus.indexOf("running") != -1 || powerStateStatus.displayStatus.indexOf("stopped") != -1 || powerStateStatus.displayStatus.indexOf("deallocated") != -1) {
                            unsubscribePendingVM(vmID, pendingVMs);
                            resolve(response);
                        }
                    })
                    .catch(e => {
                        unsubscribePendingVM(vmID, pendingVMs);
                        reject(e);
                    })
            });
    });
}

export async function stopAzureVMAndPerformAction(azureAPI: AzureApiService, vmID: string, subscriptionID: string, resourceGroup: string, vmName: string, vmState: VMState, 
        onStatusChanged: EventEmitter<VMStatusUpdateEvent>, requestMonitorStatusChange: EventEmitter<VMStatusUpdateEvent>, asyncAction: any) {
    var originalState = vmState;
    var vmStatus: VMStatus = VMStatus.init(vmID, VMState.PENDING);

    if (vmState == VMState.RUNNING) {
        // Notify UI of pending state
        onStatusChanged.emit(new VMStatusUpdateEvent(vmStatus));
        // Stop VM
        var stopVMResp = await azureAPI.stopVM(subscriptionID, resourceGroup, vmName).toPromise();
        // Get new VM details and state
        await monitorAzureStatusWithAsyncUrl(azureAPI, vmID, stopVMResp["headers"].get('Azure-AsyncOperation'), (stopVMResp["headers"].get('Retry-After') || 5), {});
        vmStatus.updateState(VMState.STOPPED);
        // Notify UI of new state
        onStatusChanged.emit(new VMStatusUpdateEvent(vmStatus));
    }

    await asyncAction();

    // Power on VM if it was originally running
    if (originalState == VMState.RUNNING) {
        // Start VM
        var startVMResp = await azureAPI.startVM(subscriptionID, resourceGroup, vmName).toPromise();
        vmStatus.updateState(VMState.PENDING);
        // Request main page to monitor the status change
        requestMonitorStatusChange.emit(new AzureRequestMonitorStatusEvent(
            vmStatus, 
            subscriptionID, 
            resourceGroup, 
            vmName,
            startVMResp["headers"].get('Azure-AsyncOperation'),
            (startVMResp["headers"].get('Retry-After') || 5)));
    }
}

// Gets all permissions for a resource and iterates through each list to determine if the user
// can start, stop, restart, deallocate, resize, or create snapshots for VMs
export function getVMPermissions(azureAPI:  AzureApiService, subscriptionID: string, resourceGroup: string, vmName: string): Promise<VMPermissions> {
    var resultPerms = VMPermissions.init(false);

    return new Promise((resolve, reject) => {
        azureAPI.getAvailableActionsForVM(subscriptionID, resourceGroup, vmName)
        .subscribe(resp => {
            if (resp && resp["value"]) {
                var allowActions = resp["value"].map(permissions => permissions["actions"]);
                var denyActions = resp["value"].map(permissions => permissions["notActions"]);

                // Check all allow actions
                setVMPermissionsForActions(allowActions, resultPerms, true);

                // Check deny actions last so that they take precedence
                setVMPermissionsForActions(denyActions, resultPerms, false);
            }
            resolve(resultPerms);
        });
    });
}

// Iterates through a list of a list of actions and computes
// the final permissions for a virtual machine
function setVMPermissionsForActions(actionList: any[], resultPerms: VMPermissions, allow: boolean) {
    if (!actionList || actionList.length == 0) return;
    
    actionList.forEach(actions => {
        if (actions && actions.length > 0) {
            actions.forEach(action => {
                if (action == "*" || action == "Microsoft.Compute/*" || action == "Microsoft.Compute/virtualMachines/*") {
                    resultPerms.start = allow;
                    resultPerms.shutdown = allow;
                    resultPerms.restart = allow;
                    resultPerms.stop = allow;
                    resultPerms.resize = allow;
                    resultPerms.snapshots = allow;
                }
                else {
                    switch (action) {
                        case "Microsoft.Compute/virtualMachines/start/action":
                            resultPerms.start = allow;
                            break;
                        case "Microsoft.Compute/virtualMachines/powerOff/action":
                            resultPerms.shutdown = allow;
                            break;
                        case "Microsoft.Compute/virtualMachines/restart/action":
                            resultPerms.restart = allow;
                            break;                    
                        case "Microsoft.Compute/virtualMachines/deallocate/action":
                            resultPerms.stop = allow;
                            break;
                        case "Microsoft.Compute/virtualMachines/write":
                            resultPerms.resize = allow;
                            break;
                        case "Microsoft.Compute/snapshots/write":
                            resultPerms.snapshots = allow;
                            break;
                    }
                }
            });
        }
    });
}

export function userCanCreateVM(stargateAPI: StargateApiService): Promise<boolean> {
    return new Promise((resolve, reject) => {
        var userEmails = getAzureUserEmail();
        if (!userEmails)
            resolve(false);
        else {
            stargateAPI.getAllowCreateVMUserList(CloudType.AZURE)
                .subscribe(resp => {
                    if (resp && resp["allowCreateVMUserList"]) {
                        var found = false;
                        for (var i = 0; i < resp["allowCreateVMUserList"]["length"]; i++) {
                            var user = resp["allowCreateVMUserList"][i] || "";
                            if (userEmails.find(email => email && email.toLowerCase() == user.toLowerCase())) {
                                found = true;
                                break;                       
                            }
                        }
                        resolve(found);
                    }
                })
        }
    });
}

// same idea as function above, but for those in the error notification mail list
export function errorNotificationEmailList(stargateAPI: StargateApiService) : Promise<boolean> {
    return new Promise((resolve, reject) => {
        var userEmails = getAzureUserEmail();
        if (!userEmails)
            resolve(false);
        else {
            stargateAPI.getErrorNotificationEmailList(CloudType.AZURE)
                .subscribe(resp => {
                    if (resp && resp["errorNotificationEmailList"]) {
                        var found = false;
                        for (var i = 0; i < resp["errorNotificationEmailList"]["length"]; i++) {
                            var user = resp["errorNotificationEmailList"][i] || "";
                            if (userEmails.find(email => email && email.toLowerCase() == user.toLowerCase())) {
                                found = true;
                                break;                       
                            }
                        }
                        resolve(found);
                    }
                })
        }
    })
}
