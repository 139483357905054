import { VMStatus } from 'src/app/dashboard/VMStatus';
import { AWSRoleInfo } from 'src/app/aws/aws-auth/aws-auth';
import { TabComponent } from './common-components/tabs/tab/tab.component';
import { int } from 'aws-sdk/clients/datapipeline'

export class BaseEvent {}

export class AzureOpenDialogEvent extends BaseEvent {
    vmID: string;
    vmName: string;
    resourceGroup: string;
    subscriptionID: string;
    vmSize: string;
    location: string;

    constructor(vmID: string, vmName: string, resourceGroup: string, subscriptionID: string, vmSize: string, location: string) {
        super();
        this.vmID = vmID;
        this.vmName = vmName;
        this.resourceGroup = resourceGroup;
        this.subscriptionID = subscriptionID;
        this.vmSize = vmSize;
        this.location = location;
    }
}

export class AWSOpenDialogEvent extends BaseEvent {
    instanceID: string;
    vmName: string;
    vmSize: string;
    
    constructor(instanceID: string, vmName: string, vmSize: string) {
        super();
        this.instanceID = instanceID;
        this.vmName = vmName;
        this.vmSize = vmSize;
    }
}

export class ScheduleOpenDialogEvent extends BaseEvent {
    // same member variables as DeprecSchedGridItemComponent
    scheduleID: string;
    vmId: string;
    cloudType: int;
    vmDetails: any;
    constructor(scheduleID: string, vmId: string, cloudType: int, vmDetails: any) {
        super();
        this.scheduleID = scheduleID;
        this.vmId = vmId;
        this.cloudType = cloudType;
        this.vmDetails = vmDetails;
    }
}

export class ScheduleDeprecatedDeletionEvent extends BaseEvent {
    scheduleID: string;
    vmId: string;
    constructor(scheduleID: string, vmId: string) {
        super();
        this.scheduleID = scheduleID;
        this.vmId = vmId;
    }
}

export class AWSRoleSelectedEvent extends BaseEvent {
    role: AWSRoleInfo;

    constructor(role: AWSRoleInfo) {
        super();
        this.role = role;
    }
}

export class CloseTabEvent extends BaseEvent {
    tab: TabComponent;

    constructor(tab: TabComponent) {
        super();
        this.tab = tab;
    }
}

export class AddScheduleEvent extends BaseEvent {
    schedules: object[];

    constructor(schedules: object[]) {
        super();
        this.schedules = schedules;
    }
}

/************** STATUS EVENTS **************/

export class VMStatusUpdateEvent extends BaseEvent {
    vmStatus: VMStatus;

    constructor(vmStatus: VMStatus) {
        super();
        this.vmStatus = vmStatus;
    }
 }

export class AzureRequestMonitorStatusEvent extends VMStatusUpdateEvent {
    subscriptionID: string;
    resourceGroup: string;
    vmName: string;
    asyncUrl: string;
    retrySeconds: number;

    constructor(vmStatus: VMStatus, subscriptionID: string, resourceGroup: string, vmName: string, asyncUrl: string = null, retrySeconds: number = 10) {
        super(vmStatus);

        this.subscriptionID = subscriptionID;
        this.resourceGroup = resourceGroup;
        this.vmName = vmName;
        this.asyncUrl = asyncUrl;
        this.retrySeconds = retrySeconds;
    }
}

export class AWSRequestMonitorStatusEvent extends VMStatusUpdateEvent {
    accountID: string;
    retrySeconds: number;

    constructor(vmStatus: VMStatus, accountID: string, retrySeconds: number = 10) {
        super(vmStatus);

        this.accountID = accountID;
        this.retrySeconds = retrySeconds;
    }
}

// Full reload event for the VM Details dialog
export class ReloadVMEvent extends BaseEvent {
   vmID: string

    constructor(vmID: string) {
        super();
        this.vmID = vmID;
    }
}